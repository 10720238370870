// SignUp.js
import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AuthContext from '../contexts/AuthContext';
import axiosInstance from '../services/axiosInstance';
import { FaGoogle } from 'react-icons/fa';
import Footer from '../components/Footer';
import { ReactComponent as Blob } from '../images/blob.svg';

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/signup', formData);
      const { token } = response.data;

      login(token);

      setMessage(response.data.message);
      setSuccess(true);

      // Redirect to /onboarding page
      navigate('/onboarding');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred.');
      }
      setSuccess(false);
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 overflow-hidden">
        <div className="max-w-6xl mx-auto px-6 lg:px-12 text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Start Your SEO<br />
            <span className="gradient-text">Automation Journey</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed"
          >
            Join hundreds of founders scaling their organic traffic
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Form Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-md mx-auto px-6 lg:px-8">
          <motion.form 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onSubmit={handleSubmit}
            className="bg-white rounded-2xl shadow-lg p-8 md:p-12 border border-gray-200"
          >
            {message && (
              <div className={`mb-6 p-4 rounded-xl text-center ${
                success ? 'bg-green-50 border border-green-200 text-green-700' : 'bg-red-50 border border-red-200 text-red-700'
              }`}>
                {message}
              </div>
            )}

            <div className="space-y-6">
              {/* Name Input */}
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                    focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                  placeholder="Your name"
                  required
                />
              </div>

              {/* Email Input */}
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                    focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                  placeholder="you@company.com"
                  required
                />
              </div>

              {/* Password Input */}
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                    focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                  placeholder="••••••••"
                  required
                />
              </div>

              {/* Submit Button */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white 
                  py-3.5 rounded-full text-lg font-semibold shadow-lg hover:shadow-purple-500/20 
                  transition-all"
              >
                Create Account
              </motion.button>

              {/* Divider */}
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              {/* Google Sign-Up */}
              <motion.a
                whileHover={{ scale: 1.02 }}
                href="https://ranqio.com/api/auth/google"
                className="flex items-center justify-center space-x-2 w-full py-3.5 
                  border border-gray-200 rounded-full bg-white hover:bg-gray-50 
                  transition-colors text-gray-700 font-medium"
              >
                <FaGoogle className="text-purple-600" />
                <span>Google</span>
              </motion.a>

              {/* Sign In Link */}
              <p className="text-center text-gray-600 mt-6">
                Already have an account?{' '}
                <Link 
                  to="/signin" 
                  className="text-purple-600 hover:text-purple-700 font-medium"
                >
                  Sign in
                </Link>
              </p>
            </div>
          </motion.form>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SignUp;