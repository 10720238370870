import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { loadStripe } from '@stripe/stripe-js';
import AuthContext from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import { Link } from 'react-router-dom';
import { ReactComponent as Blob } from '../images/blob.svg';

const stripePromise = loadStripe('pk_live_51Q2M2FB03OTgBCZpMCDIpxyRb8HUSeqnljFsWHJZnWGROTI5lZIVR1KyjyL4ITYOXkbJoD0Um4UZNiDTvVPiogpg00jmfJMguU'); 

const Pricing = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubscribe = async (priceId) => {
    if (!token) {
      navigate('/signin');
      return;
    }

    const stripe = await stripePromise;

    try {
      const response = await axiosInstance.post('/create-checkout-session', {
        priceId: priceId,
      });

      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const pricingOptions = [
    {
      title: 'Premium Monthly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$24.90/month',
      priceId: 'price_1QGQ8XB03OTgBCZp4GZbc7qN',
    },
    {
      title: 'Premium Every 3 Months',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$67.90/3 months',
      priceId: 'price_1QGQ8XB03OTgBCZpFuvXWNmx',
    },
    {
      title: 'Premium Yearly',
      description: '- 500 Keyword Searches\n- 100 Blog Post Generations',
      price: '$239.90/year',
      priceId: 'price_1QGQ8XB03OTgBCZp7Qj1KnkL',
    },
  ];

  return (
    <>
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 overflow-hidden">
        <div className="max-w-6xl mx-auto px-6 lg:px-12 text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Choose Your<br />
            <span className="gradient-text">Growth Plan</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed"
          >
            Scale your organic traffic with plans designed for startups
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            
            {/* Premium Plans */}
            {pricingOptions.map((plan, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`relative bg-white rounded-2xl shadow-lg p-8 border border-gray-200 
                  ${plan.popular ? 'ring-2 ring-purple-500' : ''}`}
              >
                {plan.popular && (
                  <div className="absolute top-0 right-0 bg-purple-500 text-white px-4 py-1 rounded-bl-xl text-sm font-bold">
                    Most Popular
                  </div>
                )}
                
                <h3 className="text-2xl font-bold mb-6 text-gray-900">{plan.title}</h3>
                
                <div className="space-y-4 mb-8">
                  {plan.description.split('\n').map((item, i) => (
                    <div key={i} className="flex items-center space-x-3">
                      <div className="w-6 h-6 bg-purple-100 rounded-full flex items-center justify-center">
                        <div className="w-3 h-3 bg-purple-500 rounded-full" />
                      </div>
                      <span className="text-gray-700">{item.replace('- ', '')}</span>
                    </div>
                  ))}
                </div>

                <div className="text-center">
                  <p className="text-3xl font-bold mb-4">{plan.price.split('/')[0]}</p>
                  <p className="text-gray-600 text-sm mb-6">
                    per {plan.price.split('/')[1]}
                  </p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleSubscribe(plan.priceId)}
                    className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white 
                      py-3 px-6 rounded-full hover:shadow-lg transition-all text-base font-medium"
                  >
                    Get Started
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;