import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import axiosInstance from '../services/axiosInstance';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';

const Menu = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [question, setQuestion] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user email when component mounts
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found.');
        }

        const response = await axiosInstance.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserEmail(response.data.email);
      } catch (err) {
        console.error('Error fetching user email:', err);
      }
    };

    fetchUserEmail();
  }, []);

  const handleSubmitQuestion = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      user_email: userEmail,
      question: question,
    };

    emailjs
      .send('service_w0pnzfj', 'template_273xm36', templateParams, 'ywHeeL1xFz31m6upy')
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setShowHelpModal(false);
          setLoading(false);
        },
        (error) => {
          console.log('FAILED...', error);
          setLoading(false);
        }
      );
  };

  // Updated link classes with gradient hover effect
  const linkClasses = `relative text-gray-700 font-medium px-3 py-2 rounded-lg 
    transition-all duration-300 hover:bg-gradient-to-r hover:from-purple-50 hover:to-blue-50 
    hover:text-gray-900`;

  return (
    <>
      <nav className="bg-white border-b border-gray-200 shadow-sm">
        <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center h-16">
          {/* Logo with gradient effect */}
          <motion.div whileHover={{ scale: 1.05 }}>
            <Link
              to="/keywords"
              className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 
                bg-clip-text text-transparent tracking-tight"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              RANQIO
            </Link>
          </motion.div>

          {/* Navigation Links */}
          <div className="hidden md:flex items-center space-x-4">
            {[
              { to: '/autopilot', text: 'Auto Pilot' },
              { to: '/keywords', text: 'Keyword Ideas' },
              { to: '/blogposts', text: 'Blog Posts' },
              { to: '/how-grow', text: 'Learn SEO' },
              { to: '/profile', text: 'Profile' },
            ].map((link, idx) => (
              <motion.div key={idx} whileHover={{ scale: 1.05 }}>
                <Link
                  to={link.to}
                  className={linkClasses}
                  style={{ fontFamily: 'Poppins, sans-serif' }}
                >
                  {link.text}
                </Link>
              </motion.div>
            ))}

            <motion.button
              whileHover={{ scale: 1.05 }}
              onClick={() => setShowHelpModal(true)}
              className={`${linkClasses} flex items-center space-x-2`}
            >
              <FaQuestionCircle className="text-purple-600" />
              <span>Help</span>
            </motion.button>
          </div>
        </div>
      </nav>

      {/* Enhanced Help Modal */}
      {showHelpModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="relative bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-xl p-8 max-w-md w-full mx-4 border border-gray-200"
          >
            <button
              onClick={() => setShowHelpModal(false)}
              className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100 transition-colors"
            >
              <FaTimes className="text-gray-600 w-5 h-5" />
            </button>

            <div className="space-y-6">
              <div className="text-center">
                <FaQuestionCircle className="w-12 h-12 text-purple-600 mx-auto mb-4" />
                <h3 className="text-2xl font-bold text-gray-900 mb-2">How can we help?</h3>
                <p className="text-gray-600">We'll respond within 24 hours</p>
              </div>

              <form onSubmit={handleSubmitQuestion} className="space-y-4">
                <textarea
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Describe your question..."
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 
                    focus:ring-purple-500 focus:border-transparent placeholder-gray-400 
                    resize-none"
                  rows="4"
                  required
                />

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={loading}
                  className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white 
                    py-3 px-6 rounded-lg font-medium transition-all disabled:opacity-50"
                >
                  {loading ? 'Sending...' : 'Send Question'}
                </motion.button>
              </form>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default Menu;