import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../services/axiosInstance';
import Footer from '../components/Footer';
import { ReactComponent as Blob } from '../images/blob.svg';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/leads', { email });
      if (response.status === 200) {
        setMessage('Success! Check your email for the ebook link.');
        setEmail(''); // Clear the email field
      } else {
        setMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setMessage('Error: Unable to submit your email. Please try again.');
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Transform Your SEO in Just 5 Minutes a Day
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 mb-12 max-w-2xl mx-auto leading-relaxed"
          >
            Busy startup founders like you don’t have time to master complex SEO strategies. <br /> 
            <strong>But here’s the truth:</strong> every day you delay investing in SEO is a missed opportunity to secure your future growth.
          </motion.p>
          <motion.form 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onSubmit={handleSubmit}
            className="bg-white text-black p-8 rounded-2xl shadow-lg max-w-md mx-auto"
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 text-lg mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              required
            />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:shadow-lg transition-all"
            >
              Get My Free Ebook
            </motion.button>
            {message && (
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="mt-4 text-lg font-medium text-green-500"
              >
                {message}
              </motion.p>
            )}
          </motion.form>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Promise Section */}
      <section className="bg-gradient-to-br from-gray-900 to-black text-white py-16 px-6">
        <div className="max-w-6xl mx-auto text-center">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-6 leading-tight"
          >
            Why You Can’t Afford to Ignore SEO Any Longer
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto leading-relaxed"
          >
            Most startups see exponential growth when they invest early in SEO.<br /> Waiting for the <strong>“right time”</strong> means falling behind competitors who are already capturing your market.
          </motion.p>
          <motion.p 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-xl text-gray-200 font-bold mb-8 max-w-2xl mx-auto leading-relaxed"
          >
            As a startup founder, you’re juggling a hundred priorities. It’s easy to push SEO to the back burner because:
          </motion.p>
          <motion.ul 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="space-y-6 max-w-2xl mx-auto"
          >
            {[
              {
                title: "SEO Takes Too Long to Show Results",
                description: "While it’s true that SEO isn’t instant, every day you delay is another day your competitors get ahead. Starting now means you’ll see results sooner.",
              },
              {
                title: "I Don’t Have Time for SEO",
                description: "That’s exactly why we created this ebook—to help you implement powerful strategies in just 5 minutes a day.",
              },
              {
                title: "SEO is Too Complicated",
                description: "It doesn’t have to be. With the right guidance, you can focus on what matters most without wasting time on technical jargon.",
              },
            ].map((item, idx) => (
              <motion.li 
                key={idx}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ delay: idx * 0.2 }}
                className="bg-gray-800 p-6 rounded-2xl text-left"
              >
                <strong className="text-xl text-purple-500">{item.title}</strong>
                <p className="text-gray-200 mt-2">{item.description}</p>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </section>

      {/* Path Section */}
      <section className="bg-white py-16 px-6">
        <div className="max-w-6xl mx-auto text-center">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-6 leading-tight"
          >
            What You'll Learn in This Ebook
          </motion.h2>
          <motion.ul 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="space-y-6 max-w-2xl mx-auto"
          >
            {[
              "Find Quick Wins with Keywords: Discover long-tail keywords that are easier to rank for and bring in targeted traffic.",
              "Create SEO-Optimized Content Effortlessly: Use AI tools to generate content without spending hours brainstorming or writing.",
              "Optimize Your Website for Instant Impact: Learn on-page tweaks that immediately improve your rankings.",
              "Track Results Like a Pro: Focus on metrics that matter without getting bogged down in data.",
            ].map((item, idx) => (
              <motion.li 
                key={idx}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ delay: idx * 0.2 }}
                className="bg-gray-50 p-6 rounded-2xl text-left"
              >
                <strong className="text-purple-600">✔</strong> {item}
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </section>

      {/* Result Section */}
      <section className="bg-gradient-to-br from-gray-50 to-white py-16 px-6">
        <div className="max-w-6xl mx-auto text-center">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-6 leading-tight"
          >
            Build a Startup That Grows While You Sleep
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto leading-relaxed"
          >
            By following the tips in this ebook, you’ll:
          </motion.p>
          <motion.ul 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="space-y-6 max-w-2xl mx-auto"
          >
            {[
              "See noticeable improvements in organic traffic within weeks.",
              "Save hours every week with simplified SEO strategies.",
              "Create a sustainable growth engine for your startup.",
            ].map((item, idx) => (
              <motion.li 
                key={idx}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ delay: idx * 0.2 }}
                className="bg-white p-6 rounded-2xl text-left shadow-lg"
              >
                <strong className="text-purple-600">✔</strong> {item}
              </motion.li>
            ))}
          </motion.ul>
          <motion.p 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-xl text-gray-600 mt-8 max-w-2xl mx-auto leading-relaxed"
          >
            Don’t let your competitors outpace you.<br /> Take 30 seconds now to invest in your startup’s future.
          </motion.p>
          <motion.form 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            onSubmit={handleSubmit}
            className="bg-white text-black p-8 rounded-2xl shadow-lg max-w-md mx-auto mt-8"
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 text-lg mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              required
            />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white py-3 px-6 rounded-lg text-lg font-semibold hover:shadow-lg transition-all"
            >
              Get My Free Ebook
            </motion.button>
            {message && (
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="mt-4 text-lg font-medium text-green-500"
              >
                {message}
              </motion.p>
            )}
          </motion.form>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default LandingPage;