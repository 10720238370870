import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { toast } from 'react-hot-toast';
import { FaClipboard, FaClipboardCheck, FaRocket, FaCogs, FaLink } from 'react-icons/fa';
import { ReactComponent as Blob } from '../images/blob.svg';

const AutoPilot = () => {
  const [marketSegment, setMarketSegment] = useState('');
  const [productService, setProductService] = useState('');
  const [autoPilotEnabled, setAutoPilotEnabled] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookSaving, setWebhookSaving] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newWebhookUrl, setNewWebhookUrl] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/user');
        setMarketSegment(response.data.marketSegment || 'N/A');
        setProductService(response.data.productService || 'N/A');
        setAutoPilotEnabled(response.data.autoPilotEnabled ?? false);
        setWebhookUrl(response.data.webhookUrl || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Failed to fetch user data.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await axiosInstance.get('/autopilot/status-list');
        setKeywords(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Auto Pilot status list:', error);
        toast.error('Failed to fetch Auto Pilot status.');
        setLoading(false);
      }
    };

    if (autoPilotEnabled) {
      fetchKeywords();
    } else {
      setKeywords([]);
      setLoading(false);
    }
  }, [autoPilotEnabled]);

  const handleToggle = async () => {
    try {
      const response = await axiosInstance.post('/autopilot/toggle', { enabled: !autoPilotEnabled });
      setAutoPilotEnabled(response.data.autoPilotEnabled);
      toast.success(`Auto Pilot ${response.data.autoPilotEnabled ? 'Enabled' : 'Disabled'}.`);

      if (!autoPilotEnabled && response.data.autoPilotEnabled) {
        const keywordsResponse = await axiosInstance.get('/autopilot/status-list');
        setKeywords(keywordsResponse.data.data);
      } else if (autoPilotEnabled && !response.data.autoPilotEnabled) {
        setKeywords([]);
      }
    } catch (error) {
      console.error('Error toggling Auto Pilot:', error);
      toast.error('Failed to toggle Auto Pilot.');
    }
  };

  const handleSaveWebhook = async () => {
    if (!newWebhookUrl) {
      toast.error('Webhook URL cannot be empty.');
      return;
    }

    const urlPattern = new RegExp('^(https?:\\/\\/)?' + '(([\\da-z.-]+)\\.([a-z.]{2,6})|' + '(([0-9]{1,3}\\.){3}[0-9]{1,3}))' + '(\\:[0-9]{1,5})?' + '(\\/[^\\s]*)?$','i');
    if (!urlPattern.test(newWebhookUrl)) {
      toast.error('Please enter a valid URL.');
      return;
    }

    setWebhookSaving(true);

    try {
      const response = await axiosInstance.post('/autopilot/integrations/webhook', { webhookUrl: newWebhookUrl });
      setWebhookUrl(response.data.webhookUrl);
      setNewWebhookUrl('');
      toast.success('Webhook URL saved successfully.');
    } catch (error) {
      console.error('Error saving Webhook URL:', error);
      toast.error('Failed to save Webhook URL.');
    } finally {
      setWebhookSaving(false);
    }
  };

  const getDifficultyLabel = (score) => {
    if (score === null || score === undefined) {
      return { label: 'N/A', className: 'text-gray-500' };
    }
    if (score <= 29) {
      return { label: 'Easy', className: 'text-green-600' };
    } else if (score <= 59) {
      return { label: 'Medium', className: 'text-yellow-600' };
    } else if (score <= 79) {
      return { label: 'Hard', className: 'text-orange-600' };
    } else {
      return { label: 'Very Hard', className: 'text-red-600' };
    }
  };

  const formatSearchVolume = (volume) => {
    if (volume === null || volume === undefined) {
      return 'N/A';
    }
    if (volume < 10) {
      return '< 10';
    } else {
      return volume;
    }
  };

  const handleCopyWebhookUrl = () => {
    navigator.clipboard.writeText(webhookUrl)
      .then(() => {
        setCopied(true);
        toast.success('Webhook URL copied to clipboard!');
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy webhook URL:', err);
        toast.error('Failed to copy webhook URL.');
      });
  };

  return (
    <>
      <Menu />

      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            <span className="gradient-text">Auto Pilot</span> Mode
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-2xl mx-auto leading-relaxed"
          >
            Automate your SEO strategy with Auto Pilot. Let Ranqio handle keyword research, content creation, and publishing while you focus on growing your business.
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Main Content Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          {/* Business Details Card */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200 mb-8"
          >
            <h3 className="text-2xl font-bold mb-8 text-gray-900 flex items-center">
              <FaRocket className="text-purple-500 mr-3" />
              Business Details
            </h3>
            <div className="space-y-6">
              <div>
                <label className="block text-gray-600 mb-2 font-medium">Market Segment</label>
                <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{marketSegment}</p>
              </div>
              <div>
                <label className="block text-gray-600 mb-2 font-medium">Product/Service</label>
                <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{productService}</p>
              </div>
            </div>
          </motion.div>

          {/* Auto Pilot Toggle Card */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200 mb-8"
          >
            <h3 className="text-2xl font-bold mb-8 text-gray-900 flex items-center">
              <FaCogs className="text-purple-500 mr-3" />
              Auto Pilot Settings
            </h3>
            <div className="flex items-center space-x-4">
              <Toggle
                checked={autoPilotEnabled}
                onChange={handleToggle}
                icons={{ checked: 'ON', unchecked: 'OFF' }}
              />
              <span className="text-lg text-gray-700">Enable Auto Pilot</span>
            </div>
          </motion.div>

          {/* Webhook Integration Card */}
          {autoPilotEnabled && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200 mb-8"
            >
              <h3 className="text-2xl font-bold mb-8 text-gray-900 flex items-center">
                <FaLink className="text-purple-500 mr-3" />
                Webhook Integration
              </h3>
              <div className="space-y-6">
                {webhookUrl && (
                  <div>
                    <label className="block text-gray-600 mb-2 font-medium">Current Webhook URL</label>
                    <div className="flex items-center space-x-2">
                      <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl flex-1 truncate">{webhookUrl}</p>
                      <button
                        onClick={handleCopyWebhookUrl}
                        className="p-2 text-gray-500 hover:text-purple-500 transition-colors"
                        title={copied ? 'Copied!' : 'Copy to clipboard'}
                      >
                        {copied ? <FaClipboardCheck /> : <FaClipboard />}
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <label className="block text-gray-600 mb-2 font-medium">New Webhook URL</label>
                  <input
                    type="text"
                    placeholder="Enter your webhook URL"
                    value={newWebhookUrl}
                    onChange={(e) => setNewWebhookUrl(e.target.value)}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleSaveWebhook}
                    disabled={webhookSaving}
                    className="mt-4 bg-gradient-to-r from-purple-600 to-blue-500 text-white py-3 px-6 rounded-full text-base font-medium hover:shadow-lg transition-all"
                  >
                    {webhookSaving ? 'Saving...' : 'Save Webhook URL'}
                  </motion.button>
                </div>
              </div>
            </motion.div>
          )}

          {/* Auto Pilot Status Table */}
          {autoPilotEnabled && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200"
            >
              <h3 className="text-2xl font-bold mb-8 text-gray-900">Auto Pilot Status</h3>
              {loading ? (
                <div className="flex justify-center items-center py-12">
                  <div className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin" />
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Keyword</th>
                        <th className="px-6 py-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Searches/Month</th>
                        <th className="px-6 py-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider">Difficulty</th>
                        <th className="px-6 py-4 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider">Blog Post</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {keywords.map((keyword, index) => (
                        <tr key={index} className="hover:bg-gray-50 transition-colors">
                          <td className="px-6 py-4 text-sm text-gray-900">{keyword.keyword}</td>
                          <td className="px-6 py-4 text-sm text-gray-600">{formatSearchVolume(keyword.avgMonthlySearches)}</td>
                          <td className="px-6 py-4">
                            <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getDifficultyLabel(keyword.difficultyScore).className}`}>
                              {getDifficultyLabel(keyword.difficultyScore).label}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-center">
                            {keyword.blogPost ? (
                              <Link to={`/blogpost/${keyword.blogPost._id}`} className="text-purple-600 hover:text-purple-700 transition-colors">
                                View Post
                              </Link>
                            ) : (
                              <span className="text-gray-500">Pending</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </motion.div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AutoPilot;