import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchBlogPostById } from '../services/api';
import ReactMarkdown from 'react-markdown';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaCalendarAlt } from 'react-icons/fa';

const BlogPostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Detect if content is plain HTML or Markdown
  function isHTML(content = '') {
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlTagRegex.test(content);
  }

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await fetchBlogPostById(id);
        setPost(result);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPost();
  }, [id]);

  // Loading state (consistent with your other pages)
  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="flex flex-col items-center space-y-4">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
              className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full"
            />
            <p className="text-xl text-gray-700 font-medium">Loading blog post...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  // If no post is found (or still loading) just return null or handle gracefully
  if (!post) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="flex flex-col items-center">
            <p className="text-xl text-gray-700">No blog post found.</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  // Format date
  const formattedDate = post.created_at
    ? new Date(post.created_at).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : '';

    return (
      <>
        <Menu />
  
        {/* Hero Section */}
        <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
          <div className="max-w-6xl mx-auto text-center relative z-10">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
            >
              {post.title}
            </motion.h1>
            {formattedDate && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center justify-center text-gray-200 space-x-2"
              >
                <FaCalendarAlt className="w-5 h-5" />
                <span className="text-lg">{formattedDate}</span>
              </motion.div>
            )}
          </div>
        </section>
  
        {/* Content Section */}
        <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
          <div className="max-w-4xl mx-auto px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="prose prose-lg md:prose-xl text-gray-800 leading-relaxed
                        prose-headings:text-gray-900 prose-a:text-purple-600
                        hover:prose-a:text-purple-700 prose-img:rounded-xl"
            >
              {isHTML(post.content) ? (
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              ) : (
                <ReactMarkdown>{post.content}</ReactMarkdown>
              )}
            </motion.div>
          </div>
        </section>
  
        <Footer />
      </>
    );
  };
  
  export default BlogPostDetail;
