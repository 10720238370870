import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axiosInstance from '../services/axiosInstance';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Select from 'react-select';
import { ReactComponent as Blob } from '../images/blob.svg';
import countryOptions from '../data/countryOptions';
import languageOptions from '../data/languageOptions';

const Onboarding = () => {
  const [formData, setFormData] = useState({
    website: '',
    marketSegment: '',
    productService: '',
    country: '', // Added country field
    language: '', // Added language field
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const navigate = useNavigate();

  // Handle input change for text fields
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle country change for dropdown
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData({
      ...formData,
      country: selectedOption ? selectedOption.value : '', // Handle null case
    });
  };

  // Handle language change for dropdown
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    setFormData({
      ...formData,
      language: selectedOption ? selectedOption.value : '', // Handle null case
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send onboarding data to the backend
      await axiosInstance.post('/onboarding', {
        website: formData.website,
        marketSegment: formData.marketSegment,
        productService: formData.productService,
        country: formData.country, // Send the selected country
        language: formData.language, // Send the selected language
      });

      // Prepare the search query
      const searchQuery = `${formData.productService}`;

      // Navigate to /keywords and pass the search query, country, and language via state
      navigate('/keywords', {
        state: {
          searchQuery,
          country: selectedCountry,
          language: selectedLanguage,
        },
      });
    } catch (error) {
      console.error('Error saving onboarding data:', error);
      // Handle error (e.g., display a message to the user)
    }
  };

  return (
    <>
      <Menu />
      
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 overflow-hidden">
        <div className="container mx-auto px-6 lg:px-12 text-center relative z-10">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Let's Setup Your<br />
            <span className="gradient-text">SEO Autopilot</span>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed"
          >
            Help us understand your business to create personalized SEO strategies
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Form Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-2xl mx-auto px-6 lg:px-8">
          <motion.form 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onSubmit={handleSubmit}
            className="bg-white rounded-2xl shadow-lg p-8 md:p-12 border border-gray-200"
          >
            {/* Website Field */}
            <div className="mb-8">
              <label className="block text-gray-700 text-sm font-medium mb-3">Website</label>
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                  focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                placeholder="https://yourwebsite.com"
              />
            </div>

            {/* Market Segment Field */}
            <div className="mb-8">
              <label className="block text-gray-700 text-sm font-medium mb-3">Ideal Customer</label>
              <input
                type="text"
                name="marketSegment"
                value={formData.marketSegment}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                  focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                placeholder="e.g., tech-savvy millennials, small business owners"
                required
              />
            </div>

            {/* Product/Service Field */}
            <div className="mb-8">
              <label className="block text-gray-700 text-sm font-medium mb-3">Product/Service</label>
              <input
                type="text"
                name="productService"
                value={formData.productService}
                onChange={handleChange}
                className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                  focus:ring-purple-500 focus:border-transparent placeholder-gray-400"
                placeholder="e.g., Mobile App Development, Personal Training"
                required
              />
            </div>

            {/* Country Dropdown */}
            <div className="mb-8">
              <label className="block text-gray-700 text-sm font-medium mb-3">Country</label>
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select country"
                classNamePrefix="modern-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '48px',
                    borderRadius: '12px',
                    borderColor: '#e5e7eb',
                    '&:hover': { borderColor: '#6366f1' },
                    '&:focus-within': { 
                      borderColor: '#6366f1',
                      boxShadow: '0 0 0 2px rgba(139, 92, 246, 0.25)'
                    }
                  }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? '#f5f3ff' : 'white',
                    color: '#1f2937',
                    borderRadius: '8px',
                    margin: '4px'
                  })
                }}
              />
            </div>

            {/* Language Dropdown */}
            <div className="mb-10">
              <label className="block text-gray-700 text-sm font-medium mb-3">Language</label>
              <Select
                options={languageOptions}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                placeholder="Select language"
                classNamePrefix="modern-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '48px',
                    borderRadius: '12px',
                    borderColor: '#e5e7eb',
                    '&:hover': { borderColor: '#6366f1' },
                    '&:focus-within': { 
                      borderColor: '#6366f1',
                      boxShadow: '0 0 0 2px rgba(139, 92, 246, 0.25)'
                    }
                  }),
                  option: (base, { isFocused }) => ({
                    ...base,
                    backgroundColor: isFocused ? '#f5f3ff' : 'white',
                    color: '#1f2937',
                    borderRadius: '8px',
                    margin: '4px'
                  })
                }}
              />
            </div>

            {/* Submit Button */}
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white 
                py-4 rounded-full text-lg font-semibold shadow-lg hover:shadow-purple-500/20 
                transition-all"
            >
              Continue to SEO Setup
            </motion.button>
          </motion.form>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Onboarding;