import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import {
  searchKeywords,
  createBlogPost,
  fetchSavedKeywords,
} from '../services/api';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';
import Modal from '../components/Modal';
import { FaInfoCircle, FaSearch, FaPlus } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { toast } from 'react-hot-toast';

import countryOptions from '../data/countryOptions';
import languageOptions from '../data/languageOptions';

const KeywordList = () => {
  const [keywords, setKeywords] = useState([]);
  const [loadingSavedKeywords, setLoadingSavedKeywords] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showTrialLimitModal, setShowTrialLimitModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [searching, setSearching] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const hasPerformedInitialSearch = useRef(false);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await axiosInstance.get('/user');
        if (!response.data.onboardingComplete) {
          navigate('/onboarding');
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
        navigate('/');
      }
    };

    checkOnboardingStatus();
  }, [navigate]);

  const fetchAllKeywords = async () => {
    setLoadingSavedKeywords(true);
    try {
      const savedResult = await fetchSavedKeywords();
      
      // **Filter out keywords searched via Auto Pilot**
      const manualKeywords = savedResult.data.filter(keyword => !keyword.searchedViaAutoPilot);
      
      setKeywords(manualKeywords);
    } catch (error) {
      console.error('Error fetching keywords:', error);
    } finally {
      setLoadingSavedKeywords(false);
    }
  };

  useEffect(() => {
    if (!hasPerformedInitialSearch.current) {
      if (location.state && location.state.searchQuery) {
        const initialQuery = location.state.searchQuery;
        setSearchQuery(initialQuery);

        if (location.state.country) {
          setSelectedCountry(location.state.country);
        }
        if (location.state.language) {
          setSelectedLanguage(location.state.language);
        }

        handleSearch(initialQuery, location.state.country, location.state.language);
      } else {
        fetchAllKeywords();
      }
      hasPerformedInitialSearch.current = true;
    }
  }, [location.state]);

  const handleSearch = async (query, country, language) => {
    if (!query || !country || !language) {
      setModalMessage('Please enter a keyword, and select both a country and language.');
      setShowTrialLimitModal(true);
      return;
    }

    setSearching(true);
    setShowSearchModal(true);

    try {
      const response = await searchKeywords(
        query,
        country.value,
        language.value,
        country.label,
        language.label
      );

      if (response.redirect_to_profile) {
        setModalMessage(
          'You have reached your trial limit for keyword searches. Please go to your profile to upgrade your subscription.'
        );
        setShowTrialLimitModal(true);
      } else {
        await fetchAllKeywords();
      }
      setSearching(false);
      setShowSearchModal(false);
    } catch (error) {
      console.error('Error searching keywords:', error);
      setSearching(false);
      setShowSearchModal(false);
      setModalMessage(
        'An error occurred while searching for keywords. Please try again.'
      );
      setShowTrialLimitModal(true);
    }
  };

  const getDifficultyLabel = (score) => {
    if (score === null || score === undefined) {
      return { label: 'N/A', className: 'text-gray-500' };
    }
    if (score <= 29) {
      return { label: 'Easy', className: 'text-green-600' };
    } else if (score <= 59) {
      return { label: 'Medium', className: 'text-yellow-600' };
    } else if (score <= 79) {
      return { label: 'Hard', className: 'text-orange-600' };
    } else {
      return { label: 'Very Hard', className: 'text-red-600' };
    }
  };

  const formatSearchVolume = (volume) => {
    if (volume === null || volume === undefined) {
      return 'N/A';
    }
    if (volume < 10) {
      return '< 10';
    } else {
      return volume;
    }
  };

  const handleCreatePost = async (keywordId) => {
    try {
      const response = await createBlogPost(keywordId);
      console.log('Response from createBlogPost:', response);

      if (response.redirect_to_profile) {
        toast.error(
          'You have reached your trial limit for blog posts. Please go to your profile to upgrade your subscription.',
          {
            duration: 8000, // 8 seconds
          }
        );
        return;
      }

      // The post is now created asynchronously in the background.
      toast.success(
        <span>
          Your post is being generated! It may take about 2 minutes. Check the <Link to="/blogposts" style={{ textDecoration: 'underline', color: '#000' }}>Blog Posts</Link> later.
        </span>,
        {
          duration: 8000, // 8 seconds
        }
      );
    } catch (error) {
      console.error('Error creating blog post:', error);
      toast.error('An error occurred while creating the blog post. Please try again.');
    }
  };

  if (loadingSavedKeywords)
    return (
      <>
        <Menu />
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="flex flex-col items-center space-y-4">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full"
            />
            <p className="text-xl text-gray-700 font-medium">Loading keywords...</p>
          </div>
        </div>
        <Footer />
      </>
    );

  const sortedKeywords = keywords.sort((a, b) => {
    const dateA = new Date(a.createdAt || 0);
    const dateB = new Date(b.createdAt || 0);

    if (dateB - dateA !== 0) {
      return dateB - dateA;
    }

    const volumeA = a.avgMonthlySearches || 0;
    const volumeB = b.avgMonthlySearches || 0;
    return volumeB - volumeA;
  });

  return (
    <>
      <Menu />

      {/* Modern Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Keyword <span className="gradient-text">Opportunities</span>
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-2xl mx-auto leading-relaxed"
          >
            Discover high-impact keywords and create optimized content that drives organic growth
          </motion.p>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          {/* Search Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-16 bg-white rounded-2xl shadow-lg p-8 border border-gray-200"
          >
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end">
              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Keyword/Topic</label>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="e.g. 'saas marketing'"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                  <FaSearch className="absolute right-3 top-3.5 text-gray-400" />
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Country</label>
                <Select
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  placeholder="Select country"
                  classNamePrefix="modern-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.5rem',
                      '&:hover': { borderColor: '#6366f1' },
                    }),
                  }}
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">Language</label>
                <Select
                  options={languageOptions}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                  placeholder="Select language"
                  classNamePrefix="modern-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      minHeight: '44px',
                      borderRadius: '0.5rem',
                      '&:hover': { borderColor: '#6366f1' },
                    }),
                  }}
                />
              </div>

              <motion.button 
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleSearch(searchQuery, selectedCountry, selectedLanguage)}
                disabled={searching}
                className="h-[44px] bg-gradient-to-r from-purple-600 to-blue-500 text-white rounded-lg font-medium flex items-center justify-center space-x-2"
              >
                <FaSearch className="w-4 h-4" />
                <span>{searching ? 'Analyzing...' : 'Find Keywords'}</span>
              </motion.button>
            </div>
          </motion.div>

          {/* Keywords Table */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-200"
          >
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {['Search Date', 'Keyword', 'Volume', 'Difficulty', 'Action'].map((header, idx) => (
                      <th 
                        key={idx}
                        className="px-6 py-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider"
                      >
                        {header}
                        {header === 'Volume' || header === 'Difficulty' ? (
                          <FaInfoCircle
                            data-tooltip-id={`${header.toLowerCase()}-tooltip`}
                            className="inline ml-2 text-gray-400 cursor-pointer hover:text-purple-500"
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                
                <tbody className="divide-y divide-gray-200">
                  {sortedKeywords.map((keyword, index) => (
                    <motion.tr
                      key={index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="hover:bg-gray-50 transition-colors"
                    >
                      <td className="px-6 py-4 text-sm text-gray-600">
                        {moment(keyword.createdAt).format('MMM D, YYYY')}
                      </td>
                      <td className="px-6 py-4 font-medium text-gray-900">
                        {keyword.keyword}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-600">
                        {formatSearchVolume(keyword.avgMonthlySearches)}
                      </td>
                      <td className="px-6 py-4">
                        <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getDifficultyLabel(keyword.difficultyScore).className}`}>
                          {getDifficultyLabel(keyword.difficultyScore).label}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => handleCreatePost(keyword._id)}
                          className="bg-gradient-to-r from-purple-600 to-blue-500 text-white px-4 py-2 rounded-lg text-sm font-medium flex items-center space-x-2"
                        >
                          <FaPlus className="w-4 h-4" />
                          <span>Create Post</span>
                        </motion.button>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </motion.div>

          {/* Tooltips */}
          <ReactTooltip
            id="volume-tooltip"
            place="top"
            content="Average monthly search volume for this keyword."
          />
          <ReactTooltip
            id="difficulty-tooltip"
            place="top"
            content="Difficulty score for ranking this keyword. New sites should focus in Easy keywords."
          />

          {/* Modern Loading Modal */}
          {showSearchModal && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
              <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="bg-white rounded-2xl p-8 max-w-md w-full border border-gray-200"
              >
                <div className="text-center space-y-6">
                  <div className="flex justify-center">
                    <div className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin" />
                  </div>
                  <div className="space-y-2">
                    <h3 className="text-xl font-semibold text-gray-900">Analyzing Keywords</h3>
                    <p className="text-gray-600">Our AI is evaluating search patterns and competition levels...</p>
                  </div>
                </div>
              </motion.div>
            </div>
          )}

          {/* Trial Limit Modal */}
          {showTrialLimitModal && (
            <Modal
              message={modalMessage}
              onClose={() => setShowTrialLimitModal(false)}
              onAction={() => navigate('/profile')}
              actionText="Upgrade Now"
              theme="gradient" // Add gradient theme to your Modal component
            />
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default KeywordList;