// src/components/HomeMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const HomeMenu = () => {
  return (
    <nav className="bg-black/90 backdrop-blur-sm border-b border-gray-800 py-3">
      <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center">
        {/* Logo with Gradient */}
        <motion.div whileHover={{ scale: 1.05 }}>
          <Link
            to="/"
            className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-blue-400 
              bg-clip-text text-transparent tracking-tight"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            RANQIO
          </Link>
        </motion.div>

        {/* Navigation Links */}
        <div className="flex items-center space-x-4">
          <motion.div whileHover={{ scale: 1.05 }}>
            <a
              href="/blog/"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Blog
            </a>
          </motion.div>

          <motion.div whileHover={{ scale: 1.05 }}>
            <a
              href="/seo-guide-ebook"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Free SEO Ebook
            </a>
          </motion.div>

          <motion.div whileHover={{ scale: 1.05 }}>
            <Link
              to="/signin"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Sign In
            </Link>
          </motion.div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/signup"
              className="px-6 py-2.5 bg-gradient-to-r from-purple-600 to-blue-500 
                text-white rounded-full font-medium shadow-lg hover:shadow-purple-500/20
                transition-all"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Try Free
            </Link>
          </motion.button>
        </div>
      </div>
    </nav>
  );
};

export default HomeMenu;