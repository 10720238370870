import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaLightbulb, FaSearch, FaEdit, FaSeedling } from 'react-icons/fa';
import { ReactComponent as Blob } from '../images/blob.svg';
import howSeoWorks from '../images/howseoworks.webp';
import makeYourOwn from '../images/makeyourown.webp';
import longTail from '../images/longtail.webp';

const Education = () => {
  return (
    <>
      <Menu />
      
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-8 leading-tight"
          >
            Mastering SEO with <span className="gradient-text">Ranqio</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-3xl mx-auto leading-relaxed"
          >
            Your guide to organic growth through effective SEO strategies
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Content Container */}
      <div className="max-w-7xl mx-auto py-16 px-6 lg:px-8">
        {/* How SEO Works */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-24 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              <FaLightbulb className="inline-block text-yellow-400 mr-3" />
              How Search Optimization Works
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              SEO enhances your website's visibility for relevant searches. Through strategic optimization, 
              you can attract quality traffic and grow your business organically.
            </p>
            <div className="p-6 bg-white rounded-xl shadow-sm border border-gray-200">
              <p className="text-gray-700 italic">
                "Think of SEO as securing prime digital real estate - the better your position, 
                the more customers you'll attract."
              </p>
            </div>
          </div>
          <motion.div whileHover={{ scale: 1.02 }} className="lg:order-last">
            <img
              src={howSeoWorks}
              alt="SEO process illustration"
              className="w-full h-auto rounded-2xl shadow-xl"
            />
          </motion.div>
        </motion.section>

        {/* Long-Tail Keywords */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-24 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              <FaSearch className="inline-block text-blue-400 mr-3" />
              Mastering Long-Tail Keywords
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Target specific search phrases that attract ideal customers while facing less competition.
            </p>
            <div className="p-6 bg-white rounded-xl shadow-sm border border-gray-200">
              <p className="font-semibold text-gray-900 mb-2">Example Strategy:</p>
              <p className="text-gray-700">
                "project management software" → "project management for remote teams"
              </p>
            </div>
          </div>
          <motion.div whileHover={{ scale: 1.02 }}>
            <img
              src={longTail}
              alt="Keyword strategy"
              className="w-full h-auto rounded-2xl shadow-xl"
            />
          </motion.div>
        </motion.section>

        {/* Content Personalization */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-24 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              <FaEdit className="inline-block text-green-400 mr-3" />
              Perfecting AI-Generated Content
            </h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Enhance Ranqio's output with your unique brand voice and insights for authentic engagement.
            </p>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="bg-purple-100 text-purple-600 p-2 rounded-lg mr-3">✨</span>
                <span className="text-gray-700">Add personal anecdotes</span>
              </li>
              <li className="flex items-start">
                <span className="bg-blue-100 text-blue-600 p-2 rounded-lg mr-3">📈</span>
                <span className="text-gray-700">Include industry-specific examples</span>
              </li>
            </ul>
          </div>
          <motion.div whileHover={{ scale: 1.02 }} className="lg:order-last">
            <img
              src={makeYourOwn}
              alt="Content editing"
              className="w-full h-auto rounded-2xl shadow-xl"
            />
          </motion.div>
        </motion.section>

        {/* Growth Strategies */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-24"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
            <FaSeedling className="inline-block text-teal-400 mr-2" />
            Organic Growth Playbook
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { 
                icon: <FaLightbulb className="text-yellow-400 h-8 w-8" />,
                title: "Consistent Publishing",
                text: "Maintain regular content updates"
              },
              {
                icon: <FaSearch className="text-blue-400 h-8 w-8" />,
                title: "Audience Insight",
                text: "Address customer pain points"
              },
              {
                icon: <FaEdit className="text-green-400 h-8 w-8" />,
                title: "Compelling Headlines",
                text: "Create clear, engaging titles"
              },
              {
                icon: <FaSeedling className="text-teal-400 h-8 w-8" />,
                title: "Strategic Patience",
                text: "Nurture long-term growth"
              }
            ].map((strategy, idx) => (
              <motion.div 
                key={idx}
                whileHover={{ y: -5 }}
                className="p-8 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="text-center">
                  <div className="inline-block mb-4">{strategy.icon}</div>
                  <h3 className="text-xl font-semibold mb-3">{strategy.title}</h3>
                  <p className="text-gray-700">{strategy.text}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* CTA Section */}
        <motion.section 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center py-16"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Ready for Organic Growth?
          </h2>
          <p className="text-lg text-gray-700 mb-8 max-w-2xl mx-auto">
            Start implementing these strategies today with Ranqio's powerful SEO tools
          </p>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/keywords"
              className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white py-4 px-12 rounded-full text-lg font-semibold hover:shadow-xl transition-all"
            >
              Launch Your Strategy →
            </Link>
          </motion.div>
        </motion.section>
      </div>

      <Footer />
    </>
  );
};

export default Education;