import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Blob } from '../images/blob.svg';
import { FaRegEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-br from-purple-50 to-blue-50 border-t border-gray-200 mt-24 overflow-hidden">
      {/* Background Element */}
      <div className="absolute -top-32 left-0 w-full h-full opacity-10">
        <Blob className="w-full h-full text-purple-200" />
      </div>

      <div className="container mx-auto px-6 lg:px-12 py-12 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="flex flex-col items-center space-y-6"
        >
          <motion.a
            whileHover={{ scale: 1.05 }}
            href="mailto:hey@ranqio.com"
            className="flex items-center text-xl font-bold text-gray-900 space-x-2"
          >
            <FaRegEnvelope className="text-purple-600" />
            <span>hey@ranqio.com</span>
          </motion.a>

          <p className="text-gray-600 text-center">
            Built for founders who value their time ⏳<br />
            <span className="text-gray-500 text-sm mt-2 block">
              &copy; {new Date().getFullYear()} Ranqio. All rights reserved.
            </span>
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;